<template>
  <v-card>
    <v-card-title>
      <v-btn
        v-show="selectedNicknames.length"
        class="mr-6"
        icon
        color="error"
        @click="tryDeleteManyNicknames(selectedNicknames)"
      >
        <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
      </v-btn>
      <TableSearch
        v-model="tableOptions.search"

        hide-filter
      />
      <v-spacer />
      <AddNewNickname
        @submitted="tryFetchNicknames"
      />
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-model="selectedNicknames"
        hide-default-footer
        :mobile-breakpoint="0"
        item-key="id"
        loading-text="Loading nicknames ..."
        show-expand
        :server-items-length="total"
        :loading="loading"
        :items="nicknames"
        :headers="nicknameHeaders"
        :options.sync="tableOptions"
        :footer-props="{
          'items-per-page-options': pages,
          'show-first-last-page': true,
          'show-current-page': true,
        }"
      >
        <!-- SELECT ROW-->
        <template v-slot:header.data-table-select="{ props, on }">
          <CustomSelectRows
            :value="props"
            :page="tableOptions.page"
            :total="total"
            v-on="on"
          />
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td colspan="1"></td>
          <td
            :colspan="headers.length - 1"
          >
            <v-row class="my-1">
              <v-col
                v-for="(v, index) in item.value"
                :key="index"
                cols="6"
                md="4"
              >
                {{ v }}
              </v-col>
            </v-row>
          </td>
        </template>
        <template v-slot:item.action="{ item }">
          <TableActions
            :loading="item.loading"
            @edit="openNicknameEditModal(item)"
            @delete="tryDeleteNickname(item.id)"
          />
        </template>
        <template v-slot:footer="{ props, on }">
          <TableFooter
            v-bind="props"
            v-on="on"
          />
        </template>
      </v-data-table>
    </v-card-text>

    <NicknameEditModal
      ref="editNicknameModal"
      @update="tryUpdateNickname"
    />

    <ConfirmDialog
      ref="deleteNicknameConfirm"
      max-width="400"
    >
      <template v-slot="{agree, cancel}">
        <v-card>
          <v-card-title>Delete nickname</v-card-title>
          <v-card-text>
            Do you really want to delete nickname
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="error"
              @click="agree"
            >
              <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
              Delete
            </v-btn>
            <v-btn
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-card>
</template>

<script>
import {
  mdiDeleteForever,
  mdiDotsVertical,
  mdiPencil,
  mdiMagnify,
} from '@mdi/js'
import pick from 'lodash/pick'
import { mapActions, mapMutations, mapState } from 'vuex'
import store from '@/store'
import discordStoreModule, { DISCORD_MODULE_NAME } from '@/store/modules/discordStoreModule'

import CustomSelectRows from '@/components/CustomSelectRows.vue'
import AddNewNickname from '@/components/discord/profile-data/nickname/AddNew.vue'
import NicknameEditModal from '@/components/discord/profile-data/nickname/EditModal.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import TableActions from '@/components/TableActions.vue'
import TableFooter from '@/components/TableFooter.vue'
import TableSearch from '@/components/TableSearch.vue'

export default {
  components: {
    CustomSelectRows,
    AddNewNickname,
    NicknameEditModal,
    ConfirmDialog,
    TableActions,
    TableFooter,
    TableSearch,
  },
  data() {
    return {
      total: 50,
      loading: true,
      tableOptions: {
        search: this.$route.query.search || '',
        sortBy: this.$route.query.sortBy ? [this.$route.query.sortBy] : [],
        sortDesc: +this.$route.query.sortDesc ? [Boolean(+this.$route.query.sortDesc)] : [],
        page: +this.$route.query.page ? +this.$route.query.page : 1,
        itemsPerPage: +this.$route.query.limit ? +this.$route.query.limit : localStorage.getItem('itemsPerPage') || 50,
      },
      search: '',
      selectedNicknames: [],
      pages: [50, 100, 200],
      icons: {
        mdiDotsVertical,
        mdiDeleteForever,
        mdiPencil,
        mdiMagnify,
      },
      nicknameHeaders: [
        {
          text: 'Text',
          value: 'name',
          sortable: true,
          filterable: true,
          align: 'start',
        },
        {
          align: 'end',
          value: 'action',
          sortable: false,
        },
      ],
    }
  },

  computed: {
    ...mapState({
      nicknames(state) {
        return state[DISCORD_MODULE_NAME]?.nickname.nicknames
      },
    }),
    mappedOptions() {
      return this.$route.query
    },
  },

  watch: {
    total() {
      this.tableOptions.page = 1
    },
    tableOptions: {
      handler(value) {
        const query = pick(value, ['sortBy', 'page', 'search', 'status'])

        query.limit = value.itemsPerPage

        if (value.sortBy?.length) {
          [query.sortBy] = value.sortBy
        }

        if (value.sortDesc?.length) {
          [query.sortDesc] = value.sortDesc
        }

        Object.keys(query).forEach(key => {
          if (!query[key] && key !== 'sortDesc') {
            delete query[key]
          }
        })

        this.tryFetchNicknames(query)
        this.$router.replace({ query }).catch(() => {})
      },
      deep: true,
    },
  },

  mounted() {
    // Register module
    if (!store.hasModule(DISCORD_MODULE_NAME)) {
      store.registerModule(DISCORD_MODULE_NAME, discordStoreModule)
    }
  },

  methods: {
    ...mapActions({
      fetchNicknames(dispatch, payload) {
        return dispatch(`${DISCORD_MODULE_NAME}/fetchNicknames`, payload)
      },
      updateNickname(dispatch, payload) {
        return dispatch(`${DISCORD_MODULE_NAME}/updateNickname`, payload)
      },
      removeNickname(dispatch, payload) {
        return dispatch(`${DISCORD_MODULE_NAME}/removeNickname`, payload)
      },
    }),

    ...mapMutations({
      commitSetNicknames(commit, payload) {
        return commit(`${DISCORD_MODULE_NAME}/SET_NICKNAMES `, payload)
      },
      commitEditNickname(commit, payload) {
        return commit(`${DISCORD_MODULE_NAME}/EDIT_NICKNAME`, payload)
      },
      commitDeleteNickname(commit, payload) {
        return commit(`${DISCORD_MODULE_NAME}/REMOVE_NICKNAME`, payload)
      },
    }),

    async tryFetchNicknames(params = this.mappedOptions) {
      this.loading = true
      const { data } = await this.fetchNicknames(params)
      this.total = data.total
      this.loading = false
    },

    async tryDeleteNickname(id) {
      this.$refs.deleteNicknameConfirm.open().then(() => {
        this.commitEditNickname({ id, loading: true })
        this.removeNickname({ id }).then(() => {
          this.commitDeleteNickname({ id })
          this.$message.success('Nickname deleted successfully')
        }).catch(err => {
          this.$message.error(err)
        })
      })
    },

    async tryUpdateNickname(value) {
      this.commitEditNickname({ ...value, loading: true })
      const { data } = await this.updateNickname({ nickname: value })
      this.commitEditNickname(data)
    },

    openNicknameEditModal(nickname) {
      this.$refs.editNicknameModal.open(nickname)
    },
  },
}
</script>
