<template>
  <v-form ref="form">
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="text">Name</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-text-field
          id="name"
          v-model="name"
          outlined
          dense
          hide-details="auto"
          placeholder="Enter name"
          :rules="[required,stringLengthValidation(2,32)]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="values">Values</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-textarea
          id="values"
          v-model="values"
          type="textarea"
          outlined
          dense
          hide-details="auto"
          placeholder="Enter values"
          :rules="[isNotEmptyString]"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { ref } from '@vue/composition-api'

import { required, isNotEmptyString, stringLengthValidation } from '@core/utils/validation'

export default {
  props: {
    nickname: {
      type: Object,
      default: () => ({}),
    },
  },
  setup({ nickname }) {
    const form = ref(null)
    const validate = () => form.value.validate()

    const reset = () => {
      form.value.reset()
    }
    const values = ref(nickname.value.join('\n'))
    const name = ref(nickname.name)

    const submit = async () => ({
      id: nickname.id,
      name: name.value,
      value: values.value.trim().split('\n').filter(el => el).filter(el => el),
    })

    return {
      form,
      name,
      values,

      validate,
      reset,
      required,
      isNotEmptyString,
      submit,
      stringLengthValidation,
    }
  },
}
</script>
